import React, { useEffect, useContext, useState } from "react";
import "./Services_desk.css";
import "./Services_mob.css";
import { MobileContext } from "../App";
import PageHeader from "../wrappers/PageHeader";
import { financialPlanningImg } from "../data/imageData";
import HomeLink from "../wrappers/HomeLink";
import HomeArrow from "../wrappers/HomeArrow";
import ImageCard from "../components/ImageCard";
import HomePagination from "../wrappers/HomePagination";
import FilePopup from "../downloads/FilePopup";

export default function FinancialPlanning({
  content,
  isHome,
  isIntersected,
  index,
  slideUp,
  slideDown,
  imageCardsData,
  maxPages,
}) {
  let isMobile = useContext(MobileContext);

  const latestFPBrochure =
    "https://europe-west2-throgmortoncapital.cloudfunctions.net/getLatestFPBrochure";

  const [isVisible, setIsVisible] = useState(false);
  const [filePopupVisible, showFilePopup] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [fpUrl, setFpUrl] = useState("");

  useEffect(() => {
    if (!isHome)
      document.title = "Financial Planning | Throgmorton Capital Management";

    // setIsVisible(false);

    if (isIntersected === false && !isMobile) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }

    if (isMobile) {
      setIsVisible(true);
    }
  }, [isHome, isIntersected, isMobile]);

  useEffect(() => {
    fetch(latestFPBrochure)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setFpUrl(data.brochures[0].document.url);
        }
      });
  }, []);

  const handleShowPdf = (e, fileSrc) => {
    e.preventDefault();
    setFileSrc(fileSrc);
    showFilePopup(true);
  };

  return (
    <section id="financial-planning" className="page-section services-section">
      <div className="page-col-1">
        <img
          data-index={index}
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
          className={
            isIntersected || !isHome
              ? "page-img animate__animated animate__fadeIn animate__slow fp-img"
              : "page-img fp-img"
          }
          // className="page-img"
          src={financialPlanningImg}
          alt=""
        />
        {isMobile ? <PageHeader title="Financial Planning" /> : null}
      </div>
      <article
        data-index={index}
        className={isHome ? "page-col-2" : "page-col-2 fp-col-2"}
      >
        {!isMobile ? (
          <h1
            onAnimationStart={() => {
              setIsVisible(true);
            }}
            style={{
              visibility: isVisible ? "visible" : "hidden",
            }}
            className={
              isIntersected || !isHome
                ? "page-header animate__animated animate__fadeInDown animate__slow"
                : "page-header"
            }
          >
            Financial Planning
          </h1>
        ) : null}
        {filePopupVisible && (
          <FilePopup fileSrc={fileSrc} showFilePopup={showFilePopup} />
        )}

        {content.map((data, i) => {
          switch (data.type) {
            case "text":
              return (
                <p
                  key={data.id}
                  style={{
                    visibility: isVisible ? "visible" : "hidden",
                    animationDuration: "4.5s",
                  }}
                  className={
                    isIntersected || !isHome
                      ? "about-text animate__animated animate__fadeIn animate__slower"
                      : "about-text"
                  }
                >
                  {data.content}
                </p>
              );

            case "olist":
              return (
                <ol className="page-sub-list animate__animated animate__fadeIn animate__slow">
                  {data.content.map((c, i) => {
                    return <li key={c.id}>{c}</li>;
                  })}
                </ol>
              );

            case "ulist":
              return (
                <ul className="page-sub-list animate__animated animate__fadeIn animate__slow">
                  {data.content.map((c, i) => {
                    return <li key={c.id}>{c}</li>;
                  })}
                </ul>
              );

            case "h5":
              return (
                <h5 className=" animate__animated animate__fadeIn animate__slow">
                  {data.content}
                </h5>
              );
            default:
              return null;
          }
        })}

        {!isHome && (
          <div className="image-card-grid fp-image-card-grid">
            {imageCardsData.map((c, i) => {
              return (
                <ImageCard
                  key={c.id}
                  imgUrl={c.img}
                  title={c.title}
                  content={c.text}
                  linkTitle={c.linkText}
                  link={fpUrl}
                  customAction={handleShowPdf}
                />
              );
            })}
          </div>
        )}
        {isHome && (
          <div>
            <HomeLink
              isIntersected={isIntersected}
              isVisible={isVisible}
              pathname="/financial-planning"
            />
          </div>
        )}
        {isHome && !isMobile && (
          <div className="arrow-container-page">
            <HomeArrow
              slideUp={slideUp}
              index={index}
              color="gold"
              previous="hero"
              type="UP"
            />

            <HomePagination page={index} maxPages={maxPages} />
            <HomeArrow
              slideDown={slideDown}
              index={index}
              color="gold"
              next="financial-planning"
              type="DOWN"
            />
          </div>
        )}
      </article>
    </section>
  );
}
