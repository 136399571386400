export const locationData = [
  {
    city: "3 The Stables",
    addrLine1: "Wilmslow Road",
    addrLine2: "East Didsbury",
    addrLine3: "Manchester",
    addrLine4: "M20 5PG",
    telephone: "0161 249 6171",
    hideLinkedIn: true,
    hideEmail: false,
  },
  {
    city: "The Old Library",
    addrLine1: "231 Eaves Lane",
    addrLine2: "Chorley",
    addrLine3: "Lancashire",
    addrLine4: "PR6 0AG",
    telephone: "01257 261 055",
    hideLinkedIn: true,
    hideEmail: true,
  },
  {
    city: "1st Floor Unit 8",
    addrLine1: "Vantage Court",
    addrLine2: "Riverside Business Park",
    addrLine3: "Barrowford",
    addrLine4: "BB9 6BP",
    telephone: "01282 614 444",
    hideLinkedIn: true,
    hideEmail: true,
  },
];
