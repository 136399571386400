import "animate.css";
import React, { useEffect, useRef } from "react";
import "./Menu_desk.css";
import "./Menu_mob.css";

import { Link } from "react-router-dom";
import Location from "../contact/Location";
import { locationData } from "../data/locationData";
import { pageLinks } from "../data/pageLinks";
import linkedin2 from "../media/linkedin2.svg";
import logo from "../media/throgmorton-logo-white.png";

export default function Menu({ menuVisible }) {
  const menuRef = useRef();

  useEffect(() => {
    const linkedin = document
      .getElementsByClassName("linkedin-follow")[0]
      .cloneNode(true);

    const linkedinActual =
      document.getElementsByClassName("linkedin-follow")[0];

    if (linkedin.hasChildNodes()) {
      menuRef.current.appendChild(linkedin);
      document.body.removeChild(linkedinActual);
    }

    if (menuVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, []);

  return (
    <div
      // ref={menuRef}
      style={{
        right: menuVisible ? 0 : "-100%",
      }}
      className="menu-container"
    >
      {/* <h1 className="menu-header">Throgmorton Capital Management</h1> */}

      <div className="menu-content-col menu-mob-col-1">
        <div>
          <a href="/">
            <img
              className="throg-logo-header header-menu"
              src={logo}
              alt="Throgmorton Capital Management logo"
            />
          </a>
          {/* </Link> */}
        </div>
        {pageLinks.map((l, i) => {
          return (
            <div
              key={l.id}
              className={
                menuVisible
                  ? "link-cont-mob animate__animated animate__fadeInDown animate__slower"
                  : "link-cont-mob"
              }
            >
              <Link
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                  });
                }}
                className={l.className}
                to={{
                  pathname: `/${l.link}`,
                  state: { hideMenu: true },
                }}
              >
                {l.text}
              </Link>
            </div>
          );
        })}
      </div>

      <div className="menu-content-col">
        <div>
          <h3 className="menu-subheading">Telephone</h3>
          <div
            className={
              menuVisible
                ? "animate__animated animate__fadeInDown animate__slower"
                : ""
            }
          >
            <a
              className="contact-link"
              href={`tel:+44${locationData[0].telephone}`}
            >
              {locationData[0].telephone}
            </a>
          </div>
        </div>
        <div>
          <h3 className="menu-subheading">Email</h3>
          <div
            className={
              menuVisible
                ? "animate__animated animate__fadeInDown animate__slower"
                : ""
            }
          >
            <a
              className="contact-link"
              href="mailto:enquiries@throgmortoncm.co.uk"
            >
              enquiries@throgmortoncm.co.uk
            </a>
          </div>
        </div>
        <div className="linkedin-container" ref={menuRef}>
          <a
            className="throg-footer-link linkedin-link"
            href="https://linkedin.com/company/throgmorton-capital-management"
            target="_blank"
          >
            <img className="menu-linkedin-img" src={linkedin2} alt="" />
          </a>
        </div>
      </div>

      <div className="menu-address">
        <h3 className="locations-header">Locations</h3>
        <div className="address-container">
          {locationData.map((l, i) => {
            return (
              <Location
                key={l.city}
                isHome={false}
                isIntersected={false}
                isVisible={true}
                isMobile={true}
                addrLine1={l.addrLine1}
                addrLine2={l.addrLine2}
                addrLine3={l.addrLine3}
                addrLine4={l.addrLine4}
                city={l.city}
                linkedin={linkedin2}
                telephone={l.telephone}
                showEmailandTel={false}
                hideEmail={true}
              />
            );
          })}
        </div>
      </div>
      <div className="menu-footer">
        <div className="menu-footer-link-container">
          <div>
            <a
              style={{ color: "whitesmoke" }}
              href="/privacy"
              className="throg-footer-link"
            >
              Privacy Policy
            </a>
          </div>
          <div className="bullet">&bull;</div>
          {/* <div>
            <a className="throg-footer-link" href="/regulatory">
              Pillar III Disclosure and Remuneration Policy
            </a>
          </div> */}
          {/* <div className="bullet">&bull;</div> */}
          <div>
            <a className="throg-footer-link" href="/best-execution">
              Best Execution Policy
            </a>
          </div>
          {/* <div className="bullet">&bull;</div> */}
        </div>

        <div className="disclosure-text-container">
          <p className="disclosure-text">
            Your capital is at risk. Investments can fluctuate in value and
            investors may not get the amount back they invest. Tax rules can
            change at any time.
          </p>
          <p className="disclosure-text">
            Throgmorton Capital Management Limited is authorised and regulated
            by the Financial Conduct Authority. Registered in England No.
            11557030. Registered Office:{" "}
            {Object.keys(locationData[0]).map((key) => {
              if (key === "telephone" || key === "hideLinkedIn") {
                return;
              } else {
                if (key === "addrLine4") return `${locationData[0][key]} `;
                return `${locationData[0][key]}, `;
              }
            })}
          </p>
        </div>
      </div>
    </div>
  );
}
